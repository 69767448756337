import { Constants } from '../../app/utils/Constants';

export default {
	header: {
		self: {},
		items: [
			{
				title: "Suporte via WhatsApp",
				root: true,
				alignment: "left",
				link: Constants.whatsappLink,
				icon: "fab fa-whatsapp",
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "embarcador/dashboard",
				bullet: "dot"
			},
			{
				title: "Favoritos",
				root: true,
				icon: "fas fa-star",
				page: "embarcador/favorito",
				bullet: "dot"
			},
			{
				title: "Cargas",
				root: true,
				icon: "fas fa-truck-loading",
				page: "embarcador/carga",
				bullet: "dot"
			},
			{
				title: "Leilões",
				root: true,
				icon: "fas fa-comments-dollar",
				page: "embarcador/leilao",
				bullet: "dot"
			},
			{
				title: "Fretes",
				root: true,
				icon: "fas fa-location-arrow",
				page: "embarcador/frete",
				bullet: "dot"
			},
			{
				title: "Classificação",
				root: true,
				icon: "fas fa-file",
				page: "embarcador/classificacao",
				bullet: "dot"
			},
			{
				title: "Agendamentos de Fretes",
				root: true,
				icon: "fas fa-calendar",
				bullet: "dot",
				submenu: [
					{
						title: "Gestão de carga",
						bullet: "dot",
						page: "embarcador/agendamento",
					},
					{
						title: "Agendamentos",
						bullet: "dot",
						page: "embarcador/agendamentos"
					}
				]
			},
			{
				title: "Orçamentos",
				root: true,
				icon: "fas fa-file-contract",
				page: "embarcador/orcamento",
				bullet: "dot"
			},
			{
				title: "Ofertas",
				root: true,
				bullet: "dot",
				icon: "fas fa-tags",
				submenu: [
					{
						title: "Aceitas",
						bullet: "dot",
						page: "embarcador/oferta/aceitas"
					},
					{
						title: "Pendentes",
						bullet: "dot",
						page: "embarcador/oferta/pendentes"
					},

				]
			},
			{
				title: "Avaliações",
				root: true,
				bullet: "dot",
				icon: "fas fa-star-half-alt",
				submenu: [
					{
						title: "Respondidos",
						bullet: "dot",
						page: "embarcador/avaliacao/ativas"
					},
					{
						title: "Pendentes",
						bullet: "dot",
						page: "embarcador/avaliacao/pendentes"
					},
				]
			},
			{
				title: "Pátio",
				root: true,
				bullet: "dot",
				icon: "fas fa-parking",
				submenu: [
					{
						title: "Dashboard",
						bullet: "dot",
						page: "embarcador/patio/dashboard"
					},
					{
						title: "Serviços",
						bullet: "dot",
						submenu: [
							{
								title: "Limpeza",
								bullet: "dot",
								page: "embarcador/patio/comprar/limpeza"
							},
							{
								title: "Estacionamento",
								bullet: "dot",
								page: "embarcador/patio/comprar/estacionamento"
							},
							{
								title: "Classificação de Produto",
								bullet: "dot",
								page: "embarcador/patio/comprar/classificacao"
							},
						]
					},
					{
						title: "Relatórios",
						bullet: "dot",
						submenu: [
							{
								title: "Cobrança",
								bullet: "dot",
								page: "embarcador/patio/relatorio/relatorio01"
							},
						]
					},
				]
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Transportadoras x Cargas",
						bullet: "dot",
						page: "embarcador/relatorio/relatorio01"
					},
					{
						title: "Cargas x Região",
						bullet: "dot",
						page: "embarcador/relatorio/relatorio02"
					},
					{
						title: "Transportadoras x Produtos",
						bullet: "dot",
						page: "embarcador/relatorio/relatorio03"
					},
				]
			},
			{
				title: "FastFrete Log",
				root: true,
				icon: "fas fa-truck",
				page: "embarcador/log",
				bullet: "dot"
			},
		]
	}
};