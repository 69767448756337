import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";

export default function AuthPage() {
	return (
		<Switch>
			<Route exact path="/" component={Login} />
			<Route path="/registro" component={Register} />
			<Route path="/forgot-password" component={ForgotPassword} />
			<Route path="/reset-password" component={ResetPassword} />

			<Redirect to="/error/error-v6" />
		</Switch>
	);
}
