import React, { Component } from "react";
import { login } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import * as utils from "../../../_metronic/utils/utils";
import { Formik } from 'formik';
import { connect } from "react-redux";
import { validateLogin } from "../../utils/formValidators";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Col, Container } from "react-bootstrap";
import clsx from "clsx";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

class Login extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			loadingButtonStyle: { paddingRight: "2.5rem" },
			isSubmitting: false
		};
	}

	enableLoading = () => {
		this.setState({ loading: true, loadingButtonStyle: { paddingRight: "3.5rem" } });
	};

	disableLoading = () => {
		this.setState({ loading: false, loadingButtonStyle: { paddingRight: "2.5rem" } });
	};

	submit = values => {
		this.enableLoading();

		login(values).then(({ data }) => {
			utils.setStorage('authToken', data.token, null);

			this.props.login(data);
		}).catch(({ response }) => {
			if (response?.data?.errors) return Swal.fire('Ops!', response.data.errors[0], 'error');
			else return Swal.fire('Ops!', 'Houve um problema. Entre em contato com o suporte.', 'error');
		}).finally(() => {
			this.disableLoading();
		});
	}

	render = () => {
		return (
			<Container fluid className="d-flex p-0 loginContainer">
				<Col className="p-0" xs={0} sm={0} md={0} lg={6}>
					<div style={{ backgroundImage: `url('/images/login/login-left.png')`, height: '100vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} />
				</Col>

				<Col xs={12} sm={12} md={12} lg={6} className="formSide d-flex justify-content-center">
					<Col md={9}>
						<img src="/images/logo-dark.png" width="auto" height="20" alt="Logo" />

						<div className="boxLogin">
							<h1>Acesse sua conta FastFrete e faça sua cotação de frete.</h1>

							<Formik initialValues={{ email: "", password: "", remember: '' }} validate={values => validateLogin(values)} onSubmit={values => this.submit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<FormControl fullWidth error={Boolean(touched.email && errors.email)} className="mt-5">
											<TextField type="email" variant="outlined" name="email" label="E-mail" onBlur={handleBlur} error={Boolean(touched.email && errors.email)} onChange={handleChange} value={values.email} />
											{touched.email && errors.email ? <FormHelperText>{errors.email}</FormHelperText> : null}
										</FormControl>

										<FormControl fullWidth error={Boolean(touched.password && errors.password)} className="mt-3">
											<TextField type="password" variant="outlined" name="password" label="Senha" onBlur={handleBlur} error={Boolean(touched.password && errors.password)} onChange={handleChange} value={values.password} />
											{touched.password && errors.password ? <FormHelperText>{errors.password}</FormHelperText> : null}
										</FormControl>

										<div className='rememberForgot d-block d-md-flex'>
											<a href="/forgot-password" className='forgot'>Esqueceu sua senha?</a>
										</div>

										<div className="buttonContainer">
											<button id="kt_login_signin_submit" type="submit" disabled={this.state.isSubmitting} className={`btn btn-primary btn-elevate kt-login__btn-primary 
                    						${clsx({ "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading })}`}
												style={this.state.loadingButtonStyle}>Entrar!</button>
										</div>
									</form>
								)}
							</Formik>

							<div className="registerContainerBoxLogin mt-5">
								<h2>Ainda não possui uma conta?</h2>
								<p>Não há segredo para criar uma conta na plataforma FastFrete. Crie a sua agora mesmo e já utilize os nossos serviços.</p>
							</div>

							<div className="createAcc">
								<Link to="/registro">
									<p>Criar conta  <AiOutlineArrowRight size={18} /></p>
								</Link>
							</div>
						</div>
					</Col>
				</Col>
			</Container>
		);
	}
}

export default connect(null, auth.actions)(Login);