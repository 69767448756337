/* eslint-disable no-case-declarations */
// Action Types
export const Types = {
	SET_FRETE: 'SET_FRETE',
	SET_TOTAL_TONELADAS_APROVADAS: 'SET_TOTAL_TONELADAS_APROVADAS',
	SET_FRETE_CAMINHONEIRO_ANEXO: 'SET_FRETE_CAMINHONEIRO_ANEXO',
	ADD_CAMINHONEIRO_ANEXO_TO_LIST: 'ADD_CAMINHONEIRO_ANEXO_TO_LIST',
};

// Reducer
const initialState = {
	frete: null,
	total_toneladas_aprovadas: null,
	frete_caminhoneiro_anexo: []
};

export default function reducer(state = initialState, action) {
	const { payload } = action;

	switch (action.type) {
		case Types.SET_FRETE:

			return { ...state, frete: payload.data };
		case Types.SET_TOTAL_TONELADAS_APROVADAS:

			return { ...state, total_toneladas_aprovadas: payload.data };
		case Types.SET_FRETE_CAMINHONEIRO_ANEXO:

			return { ...state, frete_caminhoneiro_anexo: payload.data };
		case Types.ADD_CAMINHONEIRO_ANEXO_TO_LIST:
			let new_lista = state.frete_caminhoneiro_anexo.concat(payload.data);

			return { ...state, frete_caminhoneiro_anexo: new_lista };
		default:
			return state;
	}
}

// Action Creators
export const reduxSetFrete = (payload) => {
	const { data } = payload;

	const action = {
		type: Types.SET_FRETE,
		payload: {
			data
		}
	};

	return action;
};

export const reduxSetTotalToneladasAprovadas = (payload) => {
	const { data } = payload;

	const action = {
		type: Types.SET_TOTAL_TONELADAS_APROVADAS,
		payload: {
			data
		}
	};

	return action;
};

export const reduxSetFreteCaminhoneiroAnexo = (payload) => {
	const { data } = payload;

	const action = {
		type: Types.SET_FRETE_CAMINHONEIRO_ANEXO,
		payload: {
			data
		}
	};

	return action;
};

export const reduxAddCaminhoneiroAnexoToList = (payload) => {
	const { data } = payload;

	const action = {
		type: Types.ADD_CAMINHONEIRO_ANEXO_TO_LIST,
		payload: {
			data
		}
	};

	return action;
};
