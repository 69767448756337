import React, { Component } from "react";
import { alterarSenha } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import { Formik } from 'formik';
import { connect } from "react-redux";
import clsx from "clsx";
import { validateReset } from "../../utils/formValidators";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Col, Container  } from "react-bootstrap";
import Swal from "sweetalert2";

class ResetPassword extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			loadingButtonStyle: { paddingRight: "2.5rem" },
			isSubmitting: false
		};
	}

	enableLoading = () => {
		this.setState({ loading: true, loadingButtonStyle: { paddingRight: "3.5rem" } });
	};

	disableLoading = () => {
		this.setState({ loading: false, loadingButtonStyle: { paddingRight: "2.5rem" } });
	};

	submit = values => {
		this.enableLoading();

		var query = {};

		window.location.search.substr(1).split("?").forEach((item) => query[item.split("=")[0]] = item.split("=")[1]);

		values.token = query?.token;

		alterarSenha(values).then((res) => {
			Swal.fire('Tudo certo!', 'Senha alterada com sucesso!', 'success').then((result) => {
				if (result.isConfirmed) window.location.href = '/';
			});
		}).catch(({ response }) => {
			if (response.data.errors.token) return Swal.fire('Ops!', "Você precisa ter um token de autentificação para alterar sua senha.", 'error');
			else if (response.data.errors) return Swal.fire('Ops!', response.data.errors[0], 'error');
			else return Swal.fire('Ops!', 'Houve um problema. Entre em contato com o suporte.', 'error');
		}).finally(() => {
			this.disableLoading();
		});
	}

	render = () => {
		return (
			<Container fluid className="d-flex p-0 loginContainer">
				<Col className="p-0" xs={0} sm={0} md={0} lg={6}>
					<div style={{ backgroundImage: `url('/images/login/login-left.png')`, height: '100vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} />
				</Col>

				<Col xs={12} sm={12} md={12} lg={6} className="formSide d-flex justify-content-center">
					<Col md={9}>
						<img src="/images/logo-dark.png" width="auto" height="20" alt="Logo" />

						<div className="boxLogin">
							<h1>Altere sua senha</h1>
							<p>Insira sua nova senha.</p>

							<Formik initialValues={{ email: '', password: '', password_confirmation: '' }} validate={(values) => validateReset(values)} onSubmit={values => this.submit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<FormControl fullWidth error={Boolean(touched.email && errors.email)} className="mt-5">
											<TextField type="email" variant="outlined" name="email" label="E-mail" onBlur={handleBlur} error={Boolean(touched.email && errors.email)} onChange={handleChange} value={values.email} />
											{touched.email && errors.email ? <FormHelperText>{errors.email}</FormHelperText> : null}
										</FormControl>

										<FormControl fullWidth error={Boolean(touched.password && errors.password)} className="mt-3">
											<TextField type="password" variant="outlined" name="password" label="Nova senha" onBlur={handleBlur} error={Boolean(touched.password && errors.password)} onChange={handleChange} value={values.password} />
											{touched.password && errors.password ? <FormHelperText>{errors.password}</FormHelperText> : null}
										</FormControl>

										<FormControl fullWidth error={Boolean(touched.password_confirmation && errors.password_confirmation)} className="mt-3">
											<TextField type="password" variant="outlined" name="password_confirmation" label="Confirmar senha" onBlur={handleBlur} error={Boolean(touched.password_confirmation && errors.password_confirmation)} onChange={handleChange} value={values.password_confirmation} />
											{touched.password_confirmation && errors.password_confirmation ? <FormHelperText>{errors.password_confirmation}</FormHelperText> : null}
										</FormControl>


										<div className="buttonContainer mt-4">
											<button id="kt_login_signin_submit" type="submit" disabled={this.state.isSubmitting} className={`btn btn-primary btn-elevate kt-login__btn-primary 
                    						${clsx({ "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading })}`}
												style={this.state.loadingButtonStyle}>Alterar!</button>
										</div>
									</form>
								)}
							</Formik>
						</div>
					</Col>
				</Col>
			</Container>
		);
	}
}

export default connect(null, auth.actions)(ResetPassword);