import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Accordion } from 'react-bootstrap-accordion';
import { listFiliaisByUsuario, trocaEmpresa } from '../../crud/embarcador/filiais.crud';
import * as utils from "../../../_metronic/utils/utils";
import * as auth from "../../store/ducks/auth.duck";
import 'react-bootstrap-accordion/dist/index.css';
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Swal from "sweetalert2";

class UserProfile extends React.Component {
	state = {
		empresa: [],
	}

	componentDidMount = () => {
		listFiliaisByUsuario().then((res) => {
			this.setState({ "empresa": res.data });
		}).catch((error) => {
			console.log(error);
		});
	};

	changeEmpresa(empresa) {
		if (empresa.filial) {
			empresa = empresa.filial;
		}
		// console.log(this);
		if (window.confirm(`Deseja alterar para a empresa ${empresa.emp_nome_fantasia} ?`)) {
			let form = new FormData();
			form.append('id_muda_empresa', empresa.id);

			trocaEmpresa(form).then(({ data }) => {
				utils.setStorage('authToken', data.token, null);
				auth.actions.login(data);
				window.location.reload(true);
			}).catch((err) => {
				return Swal.fire('Ops!', `${err.response.data.error}`, 'error');
			});
		}
	}

	render() {
		const { user, showHi, showAvatar } = this.props;
		return (
			<Dropdown className="kt-header__topbar-item kt-header__topbar-item--user" drop="down" alignRight>
				<Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-user-profile">
					<div className="kt-header__topbar-user">

						{showHi && (
							<div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column'}}>
								<span className="kt-header__topbar-username kt-hidden-mobile" style={{alignSelf: 'flex-end'}}>{user?.usu_nome}</span>
								<span className="kt-header__topbar-username kt-hidden-mobile" style={{ fontSize: 12, color: 'gray', alignSelf: 'flex-end' }}>
									{this.state?.empresa?.matriz?.id === this.state?.empresa?.usuario?.usu_id_empresa ? this.state?.empresa?.matriz?.emp_nome_fantasia :
										this.state.empresa.filiais.map((item) => {
											if (item.filial.id === this.state?.empresa?.usuario?.usu_id_empresa) return item.filial.emp_nome_fantasia;
										})
									}
								</span>

							</div>
						)}

						{/*showHi && (<span className="kt-header__topbar-username kt-hidden-mobile">{`${user?.usu_nome} #${this.state?.empresa?.usuario?.usu_id_empresa == undefined ? '' : this.state?.empresa?.usuario?.usu_id_empresa}`}</span>)*/}

						{showAvatar &&
							<div style={{
								width: '34px', height: '34px', backgroundImage: `url(${user?.empresa?.logo ? user?.empresa?.logo?.url : '/images/no-profile.png'})`,
								backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', borderRadius: '50%'
							}} />
						}
					</div>
				</Dropdown.Toggle>

				<Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
					<div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
						style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-8.jpg")})` }}>

						{showAvatar &&
							<div className="kt-user-card__avatar">
								<span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
									<img alt="" src={user?.empresa?.logo ? user?.empresa?.logo?.url : '/images/no-profile.png'} />
								</span>
							</div>
						}

						<div className="kt-user-card__name">{user?.usu_nome}</div>
					</div>


					<div className="kt-notification">
						<Link to={`/${user?.token_type === 'transportadora' ? 'transportador' : 'embarcador'}/minha-empresa`} className="kt-notification__item">
							<div className="kt-notification__item-icon">
								<i className="flaticon2-user" />
							</div>
							<div className="kt-notification__item-details">
								<div className="kt-notification__item-title kt-font-bold">Minha empresa</div>
								<div className="kt-notification__item-time">Atualize as informações da empresa.</div>
							</div>
						</Link>

						<Link to={`/${user?.token_type === 'transportadora' ? 'transportador' : 'embarcador'}/colaborador`} className="kt-notification__item">
							<div className="kt-notification__item-icon">
								<i className="fas fa-users" />
							</div>
							<div className="kt-notification__item-details">
								<div className="kt-notification__item-title kt-font-bold">Meus colaboradores</div>
								<div className="kt-notification__item-time">Informações sobre seus colaboradores.</div>
							</div>
						</Link>

						{(user?.usu_matriz == 1 && this.state?.empresa?.filiais?.length > 0) &&
							<Accordion title='Empresas' className="kt-notification__item-title kt-font-bold">
								{/* Matriz */}
								<div style={{ cursor: 'pointer' }} onClick={() => this.changeEmpresa(this.state?.empresa?.matriz)} className="kt-notification__item">
									<div className="kt-notification__item-icon">
										{this.state?.empresa?.logo ?
											<img alt="" src={user?.empresa?.logo?.url} />
											: <i className="fas fa-industry" />
										}
									</div>
									<div className="kt-notification__item-details">
										<div className="kt-notification__item-title kt-font-bold">
											{this.state?.empresa?.usuario?.usu_id_empresa === this.state?.empresa?.matriz?.id &&
												<i style={{ color: 'red' }} className="fas fa-asterisk" />
											}
											&nbsp;&nbsp;{this.state?.empresa?.matriz?.emp_nome_fantasia} [Matriz]
										</div>
										<div className="kt-notification__item-time">{this.state?.empresa?.matriz?.emp_cnpj}</div>
									</div>
								</div>

								{/* Filiais */}
								{this.state.empresa?.filiais && this.state.empresa?.filiais.map((empresa, index) => {
									return (
										<div key={index} style={{ cursor: 'pointer' }} onClick={() => this.changeEmpresa(empresa)} className="kt-notification__item">
											<div className="kt-notification__item-icon">
												<i className="fas fa-industry" />
											</div>
											<div className="kt-notification__item-details">
												<div className="kt-notification__item-title kt-font-bold">
													{this.state?.empresa?.usuario.usu_id_empresa === empresa.filial.id &&
														<i style={{ color: 'red' }} className="fas fa-asterisk" />
													}
													&nbsp;&nbsp;{empresa.filial.emp_nome_fantasia}
												</div>
												<div className="kt-notification__item-time">{empresa.filial.emp_cnpj}</div>
											</div>
										</div>);
								})}
							</Accordion>
						}

						<div className="kt-notification__custom flex-column align-items-start">
							<Link to="/logout" className="btn btn-primary btn-bold">Sair</Link>
						</div>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(UserProfile);
