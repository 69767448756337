import React, { Component } from "react";
import { esqueciSenha } from "../../crud/auth.crud";
import * as auth from "../../store/ducks/auth.duck";
import { Formik } from 'formik';
import { connect } from "react-redux";
import { FormControl, TextField } from "@material-ui/core";
import { Col, Container } from "react-bootstrap";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

class ForgotPassword extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			loadingButtonStyle: { paddingRight: "2.5rem" },
			isSubmitting: false
		};
	}

	enableLoading = () => {
		this.setState({ loading: true, loadingButtonStyle: { paddingRight: "3.5rem" } });
	};

	disableLoading = () => {
		this.setState({ loading: false, loadingButtonStyle: { paddingRight: "2.5rem" } });
	};

	submit = values => {
		if (!values.email) return Swal.fire('Ops!', 'Insira seu e-mail, antes de continuar.', 'error');

		this.enableLoading();

		esqueciSenha(values).then(({ data }) => {
			return Swal.fire('Tudo certo!', 'Foi mandando um email para você, verifique sua caixa de entrada!', 'success');
		}).catch(({ response }) => {
			if (response.data.errors) return Swal.fire('Ops!', response.data.errors[0], 'error');
			else return Swal.fire('Ops!', 'Houve um problema. Entre em contato com o suporte.', 'error');
		}).finally(() => {
			this.disableLoading();
		});
	}

	render = () => {
		return (
			<Container fluid className="d-flex p-0 loginContainer">
				<Col className="p-0" xs={0} sm={0} md={0} lg={6}>
					<div style={{ backgroundImage: `url('/images/login/login-left.png')`, height: '100vh', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} />
				</Col>

				<Col xs={12} sm={12} md={12} lg={6} className="formSide d-flex justify-content-center">
					<Col md={9}>
						<img src="/images/logo-dark.png" width="auto" height="20" alt="Logo" />

						<div className="boxLogin">
							<div>
								<h1>Esqueceu sua senha?</h1>
								<p>Insira seu email para recupera-la.</p>
							</div>

							<Formik initialValues={{ email: "" }} onSubmit={values => this.submit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<FormControl fullWidth error={Boolean(touched.email && errors.email)} className="mt-5">
											<TextField type="email" variant="outlined" name="email" label="E-mail" onBlur={handleBlur} error={Boolean(touched.email && errors.email)} onChange={handleChange} value={values.email} />
										</FormControl>

										<div className="buttonContainer">
											<button id="kt_login_signin_submit" type="submit" disabled={this.state.isSubmitting} className={`btn btn-primary btn-elevate kt-login__btn-primary mt-3`} style={{ paddingRight: '2.5rem' }}>Enviar Link</button>
										</div>
									</form>
								)}
							</Formik>

							<div className="registerContainerBoxLogin mt-5">
								<h2>Ou se já possui uma conta, entre agora.</h2>
							</div>

							<div className="createAcc">
								<Link to="/">
									<p className="mt-3">Entrar <AiOutlineArrowRight size={18} /></p>
								</Link>
							</div>
						</div>
					</Col>
				</Col>
			</Container>
		);
	}
}

export default connect(null, auth.actions)(ForgotPassword);