import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(data) {
	return axios.post(`${Constants.baseUrl}/auth`, data);
}

export function register(data) {
	return axios.post(`${Constants.baseUrl}/register`, data);
}

export function esqueciSenha(data) {
	return axios.post(`${Constants.baseUrl}/esqueci-senha`, data);
}

export function alterarSenha(data) {
	return axios.post(`${Constants.baseUrl}/alterar-senha`, data);
}