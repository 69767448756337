import { Constants } from '../../app/utils/Constants';

export default {
	header: {
		self: {},
		items: [
			{
				title: "Suporte via WhatsApp",
				root: true,
				alignment: "left",
				link: Constants.whatsappLink,
				icon: "fab fa-whatsapp",
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "transportador/dashboard",
				bullet: "dot"
			},
			{
				title: "Leilões",
				root: true,
				icon: "fas fa-comments-dollar",
				page: "transportador/leilao",
				bullet: "dot"
			},
			{
				title: "Fretes",
				root: true,
				icon: "fas fa-location-arrow",
				page: "transportador/frete",
				bullet: "dot"
			},
			{
				title: "Agendamentos de Fretes",
				root: true,
				icon: "fas fa-calendar",
				page: "transportador/agendamento",
				bullet: "dot"
			},
			{
				title: "Confirmação Faturados",
				root: true,
				icon : "fas fa-money-bill-wave",
				page: "transportador/faturados",
				bullet: "dot"
			},
			{
				title: "Orçamentos",
				root: true,
				icon: "fas fa-file-contract",
				page: "transportador/orcamento",
				bullet: "dot"
			},
			{
				title: "Ofertas",
				root: true,
				bullet: "dot",
				icon: "fas fa-tags",
				submenu: [
					{
						title: "Aceitas",
						bullet: "dot",
						page: "transportador/oferta/aceita"
					},
					{
						title: "Pendentes",
						bullet: "dot",
						page: "transportador/oferta/pendente"
					},
				]
			},
			{
				title: "Avaliações",
				root: true,
				bullet: "dot",
				icon: "fas fa-truck-loading",
				submenu: [
					{
						title: "Respondidos",
						bullet: "dot",
						page: "transportador/avaliacao/ativas"
					},
					{
						title: "Pendentes",
						bullet: "dot",
						page: "transportador/avaliacao/pendentes"
					},
				]
			},
			{
				title: "Pátio",
				root: true,
				bullet: "dot",
				icon: "fas fa-parking",
				submenu: [
					{
						title: "Dashboard",
						bullet: "dot",
						page: "transportador/patio/dashboard"
					},
					{
						title: "Serviços",
						bullet: "dot",
						submenu: [
							{
								title: "Limpeza",
								bullet: "dot",
								page: "transportador/patio/comprar/limpeza"
							},
							{
								title: "Estacionamento",
								bullet: "dot",
								page: "transportador/patio/comprar/estacionamento"
							},
							{
								title: "Classificação de Produto",
								bullet: "dot",
								page: "transportador/patio/comprar/classificacao"
							},
						]
					},
					{
						title: "Relatórios",
						bullet: "dot",
						submenu: [
							{
								title: "Cobrança",
								bullet: "dot",
								page: "transportador/patio/relatorio/relatorio01"
							},
						]
					},
				]
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Transporte",
						bullet: "dot",
						page: "transportador/relatorio/relatorio01"
					},
					{
						title: "Volume de cargas por região",
						bullet: "dot",
						page: "transportador/relatorio/relatorio02"
					},
				]
			},
			{
				title: "Cadastrar Motorista",
				root: true,
				icon: "fas fa-plus",
				page: "transportador/motorista",
				bullet: "dot"
			},
		]
	}
};
