export const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = "O campo " + value + " é obrigatório.";
		} else if (key === 'password' && values[key].length < 6) {
			errors[key] = "Use pelo menos 6 caracteres.";
		} else if (key === 'confirm' && values[key] !== values['password']) {
			errors[key] = "As senhas são diferentes.";
		} else if (key === 'password_confirmation' && values[key] !== values['password']) {
			errors[key] = "As senhas são diferentes.";
		} else if (key === 'email' && !regexEmail.test(values[key])) {
			errors[key] = "O E-mail informado é inválido.";
		}
	}

	return errors;
};

export const createFormikValidationMotorista = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = "O campo " + value + " é obrigatório.";
		}
	}

	return errors;
};

export const validateLogin = values => {
	let map = new Map();

	map.set('email', 'email');
	map.set('password', 'senha');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateRegister = (values, token) => {
	let map = new Map();

	map.set('emp_razao_social', 'razão social');
	map.set('emp_nome_fantasia', 'nome fantasia');
	map.set('emp_cnpj', 'CNPJ');
	map.set('emp_cep', 'CEP');
	map.set('emp_logradouro', 'logradouro');
	map.set('emp_logradouro_numero', 'número');

	map.set('emp_bairro', 'bairro');
	map.set('emp_municipio', 'municipio');
	map.set('emp_estado', 'estado');
	map.set('email', 'email');
	map.set('usu_nome', 'nome');
	map.set('usu_cpf', 'CPF');
	map.set('usu_cep', 'CEP');
	map.set('usu_rua', 'rua');
	map.set('usu_bairro', 'bairro');
	map.set('usu_cidade', 'cidade');
	map.set('usu_uf', 'UF');
	map.set('usu_numero', 'numero');
	map.set('emp_telefone', 'telefone');
	map.set('password', 'senha');
	map.set('confirm', 'confirmação de senha');

	if(token === 'Transportadora'){
		map.set('emp_data_inicio_atividades', 'data de inicio das atividades');
		map.set('emp_num_filiais', 'numero de filiais');
		map.set('emp_quantidade_veiculos_proprios', 'quantidade de veiculos próprios');
		map.set('emp_quantidade_veiculos_agregados', 'quantidade de veiculos agregados');
		map.set('emp_faturamento', 'faturamento');
	}

	let errors = createFormikValidation(map, values);

	return errors;
};

export const createEditMotorista = values => {
	const form = new FormData();

	form.append("cam_nome", values.cam_nome);
	form.append("cam_cpf", values.cam_cpf);
	form.append("cam_celular", values.cam_celular);

	form.append("cam_cep", values.cam_cep);
	form.append("cam_cidade", values.cam_cidade);
	form.append("cam_bairro", values.cam_bairro);
	form.append("cam_complemento", values.cam_complemento ? values.cam_complemento : '');
	form.append("cam_rua", values.cam_rua);
	form.append("cam_numero", values.cam_numero);
	form.append("cam_uf", values.cam_uf);

	form.append("cam_capacidade_ton", values.cam_capacidade_ton);
	form.append("cam_numero_eixos", values.cam_numero_eixos);
	form.append("cam_placa_um", values.cam_placa_um);
	form.append("cam_placa_dois", values.cam_placa_dois ? values.cam_placa_dois : '');
	form.append("cam_placa_tres", values.cam_placa_tres ? values.cam_placa_tres : '');
	form.append("cam_renavam", values.cam_renavam);
	form.append("cam_rntrc", values.cam_rntrc);
	form.append("cam_tipo_caminhao", values.cam_tipo_caminhao);
	form.append("cam_tipo_carroceria", values.cam_tipo_carroceria);
	form.append("cam_titular_caminhao", values.cam_titular_caminhao);
	form.append("cam_gerenciadora_riscos", values.cam_gerenciadora_riscos);

	form.append("cam_id_foto_cnh", values.cam_id_foto_cnh);
	form.append("cam_id_foto_crv_caminhao", values.cam_id_foto_crv_caminhao);
	form.append("cam_id_foto_crv_carroceria_1", values.cam_id_foto_crv_carroceria_1);
	form.append("cam_id_foto_crv_carroceria_2", values.cam_id_foto_crv_carroceria_2);
	form.append("cam_foto_cnh", values.cam_foto_cnh);
	form.append("cam_foto_crv_caminhao", values.cam_foto_crv_caminhao);
	form.append("cam_foto_crv_carroceria_1", values.cam_foto_crv_carroceria_1);
	form.append("cam_foto_crv_carroceria_2", values.cam_foto_crv_carroceria_2);

	return form;
};

export const validateRegisterMotorista = values => {
	let map = new Map();

	map.set('cam_nome', 'Nome');
	map.set('cam_cpf', 'CPF');
	map.set('cam_rntrc', 'RNTRC');
	map.set('cam_renavam', 'RENAVAM');
	map.set('cam_cep', 'CEP');
	map.set('cam_rua', 'Rua');
	map.set('cam_bairro', 'Bairro');
	map.set('cam_cidade', 'Cidade');
	map.set('cam_numero', 'Número');
	map.set('cam_uf', 'Estado');
	map.set('cam_tipo_caminhao', 'Tipo do caminhão');
	map.set('cam_capacidade_ton', 'Capacidade de toneladas');
	map.set('cam_numero_eixos', 'Número de eixos');
	map.set('cam_titular_caminhao', 'Titular do caminhão');
	map.set('cam_placa_um', 'Placa um');
	map.set('cam_placa_dois', 'Placa dois');
	map.set('cam_tipo_carroceria', 'Tipo da carroceria');
	map.set('cam_gerenciadora_riscos', 'Gerenciadora de riscos');

	let errors = createFormikValidationMotorista(map, values);

	return errors;
};

export const validateLance = values => {
	let map = new Map();

	map.set('lla_toneladas', 'volume');
	map.set('lla_valor_tonelada', 'valor da tonelada');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateNewOferta = values => {
	let map = new Map();

	map.set('ofe_tipo_carga', 'tipo de carga');
	map.set('ofe_tipo_produto', 'tipo de produto');
	map.set('ofe_tipo_caminhao', 'tipo de caminhão');
	map.set('ofe_valor_tonelada', 'valor da tonelada');
	map.set('ofe_volume', 'volume');
	map.set('ofe_cidade_origem', 'cidade origem');
	map.set('ofe_cidade_destino', 'cidade destino');
	map.set('ofe_raio_1', 'raio de origem');
	map.set('ofe_raio_2', 'raio de destino');
	map.set('ofe_data_expiracao', 'data de expiração');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateReset = values => {
	let map = new Map();

	map.set('email', 'email');
	map.set('password', 'senha');
	map.set('password_confirmation', 'confirmação de senha');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateNewColaborador = (values, edit) => {
	let map = new Map();

	map.set('usu_nome', 'email');
	map.set('email', 'email');

	if (!edit) {
		map.set('password', 'senha');
	}

	map.set('usu_celular', 'celular');
	map.set('usu_cpf', 'CPF');
	map.set('usu_cep', 'CEP');
	map.set('usu_rua', 'rua');
	map.set('usu_bairro', 'bairro');
	map.set('usu_cidade', 'cidade');
	map.set('usu_uf', 'UF');
	map.set('usu_numero', 'numero');
	//map.set('usu_complemento', 'complemento');
	map.set('usu_responsavel', 'responsável');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validatePerfil = values => {
	let map = new Map();

	map.set('emp_razao_social', 'razão social');
	map.set('emp_nome_fantasia', 'nome fantasia');
	map.set('emp_cnpj', 'CNPJ');
	map.set('emp_cep', 'CEP');
	map.set('emp_logradouro', 'logradouro');
	map.set('emp_logradouro_numero', 'número');
	map.set('emp_bairro', 'bairro');
	map.set('emp_municipio', 'municipio');
	map.set('emp_estado', 'estado');
	map.set('emp_telefone', 'telefone');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateResponderAvaliacao = values => {
	let map = new Map();

	map.set('score_roteiro_entrega', 'roteiro de entrega');
	map.set('score_agilizade_descarga', 'agilidade da descarga');
	map.set('score_agilidade_troca_de_nota', 'agilidade na troca de nota');
	map.set('score_local_de_coleta', 'organização do local de coleta');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateResponderAvaliacaoEmbarcador = values => {
	let map = new Map();

	map.set('score_agilidade_agendamento_carga', 'agilidade de agendamento de carga');
	map.set('score_agilidade_documentacao', 'agilidade da documentação');
	map.set('score_compromisso_com_lote', 'compromisso com o lote');
	map.set('score_fluxo_de_caminhoes', 'fluxo de caminhões');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateLimpeza = values => {
	let map = new Map();

	map.set('agl_id_patio', 'pátio');
	map.set('agl_id_motorista', 'motorista');
	map.set('agl_id_servico', 'serviço');

	map.set('previsao_entrada_data', 'previsão de início de limpeza');
	map.set('previsao_entrada_hora', 'hora de início de limpeza');
	map.set('previsao_saida_data', 'data de término de limpeza');
	map.set('previsao_saida_hora', 'hora de término de limpeza');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateEstacionamento = values => {
	let map = new Map();

	map.set('aes_id_patio', 'pátio');
	map.set('aes_id_motorista', 'motorista');
	map.set('aes_id_servico', 'serviço');
	map.set('aes_status_caminhao', 'status do caminhão');
	map.set('previsao_entrada_hora', 'hora da previsão de entrada');
	map.set('previsao_entrada_data', 'data previsão de entrada');
	map.set('previsao_saida_hora', 'hora da previsão de saída');
	map.set('previsao_saida_data', 'data previsão de saída');

	let errors = createFormikValidation(map, values);

	return errors;
};

export const validateClassificacaoProduto = values => {
	let map = new Map();

	map.set('acl_id_patio', 'pátio');
	map.set('acl_id_motorista', 'motorista');
	map.set('acl_id_servico', 'serviço');
	map.set('acl_placa_caminhao', 'placa do cavalinho');
	map.set('acl_produto', 'produto');
	map.set('previsao_entrada_data', 'previsão de início de classificação');
	map.set('previsao_entrada_hora', 'hora de início de classificação');
	map.set('previsao_saida_data', 'data de término de classificação');
	map.set('previsao_saida_hora', 'hora de término de classificação');

	let errors = createFormikValidation(map, values);

	return errors;
};
