import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic";

//transp
const DashboardTransportadora = lazy(() =>
  import("./transportadora/dashboard/DashboardPage")
);

const FretesTransportadora = lazy(() =>
  import("./transportadora/frete/FretePage")
);

const AgendamentosTransportadora = lazy(() =>
  import("./transportadora/agendamento/AgendamentoPage")
);

const LeiloesTransportadora = lazy(() =>
  import("./transportadora/leilao/LeilaoPage")
);

const OrcamentosTransportadora = lazy(() =>
  import("./transportadora/orcamento/OrcamentoPage")
);

const OfertasTransportadora = lazy(() =>
  import("./transportadora/oferta/OfertaPage")
);

const AvaliacaoTransportadora = lazy(() =>
  import("./transportadora/avaliacao/AvaliacaoPage")
);

const RelatorioTransportadora = lazy(() =>
  import("./transportadora/relatorios/RelatorioPage")
);

const ColaboradorTransportadora = lazy(() =>
  import("./transportadora/colaborador/ColaboradorPage")
);

const PerfilTransportadora = lazy(() =>
  import("./transportadora/perfil/PerfilPage")
);

const PatioTransportadora = lazy(() =>
  import("./transportadora/patio/PatioPage")
);

const MotoristaTransportadora = lazy(() =>
  import("./transportadora/motorista/MotoristaPage")
);

const FaturadosTransportadora = lazy(() =>
  import("./transportadora/faturados/FaturadosPage")
);

//Usina

const AgendamentoUsina = lazy(() =>
  import("./transportadora/usina/AgendamentoUsinaPage")
);

//emb
const DashboardEmbarcadora = lazy(() =>
  import("./embarcadora/dashboard/DashboardPage")
);

const EmbarcadorAgendamentoPage = lazy(() =>
  import("./embarcadora/agendamento/AgendamentoPage")
);

const EmbarcadorAgendamentosPage = lazy(() =>
  import("./embarcadora/agendamentos/AgendamentosPage")
);

const EmbarcadorFretePage = lazy(() => import("./embarcadora/frete/FretePage"));

const EmbarcadorLeilaoPage = lazy(() =>
  import("./embarcadora/leilao/LeilaoPage")
);

const EmbarcadoraFavoritoPage = lazy(() =>
  import("./embarcadora/favorito/FavoritoPage")
);

const EmbarcadoraColaborador = lazy(() =>
  import("./embarcadora/colaborador/ColaboradorPage")
);

const EmbarcadoraAvaliacao = lazy(() =>
  import("./embarcadora/avaliacao/AvaliacaoPage")
);

const EmbarcadoraOfertar = lazy(() =>
  import("./embarcadora/oferta/OfertaPage")
);

const EmbarcadoraOrcamento = lazy(() =>
  import("./embarcadora/orcamento/OrcamentoPage")
);

const EmbarcadoraRelatorio = lazy(() =>
  import("./embarcadora/relatorios/RelatorioPage")
);

const EmbarcadoraCarga = lazy(() => import("./embarcadora/carga/CargaPage"));

const EmbarcadoraPerfil = lazy(() => import("./embarcadora/perfil/PerfilPage"));

const EmbarcadoraPatio = lazy(() => import("./embarcadora/patio/PatioPage"));

const EmbarcadoraClassificacao = lazy(() =>
  import("./embarcadora/classificacao/ClassificacaoPage")
);
const EmbarcadoraFreteLog = lazy(() =>
  import("./embarcadora/fretelog/FreteLogPage")
);

function HomePage(props) {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {!props.user && (
        <>
          <Redirect exact to="/" />
        </>
      )}

      {props.user.token_type === "transportadora" ? (
        <Switch>
          {props.user?.id == 990 ||
          props.user?.id == 1760 ||
          props.user?.id == 1793 ||
          props.user?.id == 1038 ||
          props.user?.id == 949 ||
          props.user?.id == 1187 ||
          props.user?.id == 1807 ||
          props.user?.id == 1752 ||
          props.user?.id == 1810 ||
          props.user?.id == 1659 ||
          props.user?.id == 1074 ||
          props.user?.id == 1663 ||
          props.user?.id == 1382 ||
          props.user?.id == 1038 ||
          props.user?.id == 1483 ||
          props.user?.id == 1067 ||
          props.user?.id == 314  ||
          props.user?.id == 1820 ||
          props.user?.id == 968 ||
          props.user?.id == 1735 ||
          props.user?.id == 1862 ||
          props.user?.id == 1736 ||
          props.user?.id == 1867 ||
          props.user?.id == 1269 ||
          props.user?.id == 1860 ||
          props.user?.id == 1569 ||
          props.user?.id == 1890 ||
          props.user?.id == 1894 ||
          props.user?.id == 1872 ||
          props.user?.id == 1909 ||
          props.user?.id == 1911 ||
          props.user?.id == 1916 ||
          props.user?.id == 1918 ||
          props.user?.id == 1922 ||
          props.user?.id == 1923 ||
          props.user?.id == 1926 ||
          props.user?.id == 1912 ||
          props.user?.id == 1962 ||
          props.user?.id == 1964 ||
          props.user?.id == 1851 ||
          props.user?.id == 1980 ||
          props.user?.id == 1989 ||
          props.user?.id == 1990 ||
          props.user?.id == 1988 ||
          props.user?.id == 2011 ||
          props.user?.id == 2012 ||
          props.user?.id == 1838 ||
          props.user?.id == 2010 ||
          props.user?.id == 2017 ||
          props.user?.id == 2018 ||
          props.user?.id == 2019 ||
          props.user?.id == 2015 ||
          props.user?.id == 1808 ||
          props.user?.id == 2014 ||
          props.user?.id == 2016 ||
          props.user?.id == 2021 ||
          props.user?.id == 2024 ||
          props.user?.id == 2020 ||
          props.user?.id == 2026 ||
          props.user?.id == 1837 ||
          props.user?.id == 2036 ||
          props.user?.id == 2034 ||
          props.user?.id == 2048 ||
          props.user?.id == 2053 ||
          props.user?.id == 2082 ||
          props.user?.id == 2085
          
          ? (
            <Redirect exact from="/" to="/transportador/usina/agendamento" />
          ) : (
            <Redirect exact from="/" to="/transportador/dashboard" />
          )}

          <Route
            path="/transportador/dashboard"
            component={DashboardTransportadora}
          />
          <Route path="/transportador/frete" component={FretesTransportadora} />
          <Route
            path="/transportador/agendamento"
            component={AgendamentosTransportadora}
          />
          <Route
            path="/transportador/leilao"
            component={LeiloesTransportadora}
          />
          <Route
            path="/transportador/orcamento"
            component={OrcamentosTransportadora}
          />
          <Route
            path="/transportador/oferta"
            component={OfertasTransportadora}
          />
          <Route
            path="/transportador/colaborador"
            component={ColaboradorTransportadora}
          />
          <Route
            path="/transportador/avaliacao"
            component={AvaliacaoTransportadora}
          />
          <Route
            path="/transportador/relatorio"
            component={RelatorioTransportadora}
          />
          <Route
            path="/transportador/minha-empresa"
            component={PerfilTransportadora}
          />
          <Route path="/transportador/patio" component={PatioTransportadora} />
          <Route
            path="/transportador/motorista"
            component={MotoristaTransportadora}
          />
          <Route
            path="/transportador/faturados"
            component={FaturadosTransportadora}
          />
          {props.user?.id == 990 ||
          props.user?.id == 1760 ||
          props.user?.id == 1793 ||
          props.user?.id == 1038 ||
          props.user?.id == 949 ||
          props.user?.id == 1187 ||
          props.user?.id == 1807 ||
          props.user?.id == 1752 ||
          props.user?.id == 1810 ||
          props.user?.id == 1659 ||
          props.user?.id == 1074 ||    
          props.user?.id == 1663 ||
          props.user?.id == 1382 ||
          props.user?.id == 1038 ||
          props.user?.id == 1483 ||
          props.user?.id == 1067 ||
          props.user?.id == 314  ||
          props.user?.id == 1820 ||
          props.user?.id == 968 ||
          props.user?.id == 1735 ||
          props.user?.id == 1862 ||
          props.user?.id == 1736 ||
          props.user?.id == 1867 ||
          props.user?.id == 1269 ||
          props.user?.id == 1860 ||
          props.user?.id == 1569 ||
          props.user?.id == 1890 ||
          props.user?.id == 1894 ||
          props.user?.id == 1872 ||
          props.user?.id == 1909 ||
          props.user?.id == 1911 ||
          props.user?.id == 1916 ||
          props.user?.id == 1918 ||
          props.user?.id == 1922 ||
          props.user?.id == 1923 ||
          props.user?.id == 1926 ||
          props.user?.id == 1912 ||
          props.user?.id == 1962 ||
          props.user?.id == 1964 ||
          props.user?.id == 1851 ||
          props.user?.id == 1980 ||
          props.user?.id == 1989 ||
          props.user?.id == 1990 ||
          props.user?.id == 1988 ||
          props.user?.id == 2011 ||
          props.user?.id == 2012 ||
          props.user?.id == 1838 ||
          props.user?.id == 2010 ||
          props.user?.id == 2017 ||
          props.user?.id == 2018 ||
          props.user?.id == 2019 ||
          props.user?.id == 2015 ||
          props.user?.id == 1808 ||
          props.user?.id == 2014 ||
          props.user?.id == 2016 ||
          props.user?.id == 2021 ||
          props.user?.id == 2024 ||
          props.user?.id == 2020 ||
          props.user?.id == 2025 ||
          props.user?.id == 2022 ||
          props.user?.id == 2026 ||
          props.user?.id == 1837 ||
          props.user?.id == 2027 ||
          props.user?.id == 2031 ||
          props.user?.id == 2032 ||
          props.user?.id == 2036 ||
          props.user?.id == 2034 ||
          props.user?.id == 2048 ||
          props.user?.id == 2053 ||
          props.user?.id == 2082 ||
          props.user?.id == 2085 

          
          
          ? (
            <>
              <Route path="/transportador/usina" component={AgendamentoUsina} />
            </>
          ) : null}
        </Switch>
      ) : props.user.token_type === "embarcadora" ? (
        <Switch>
          {<Redirect exact from="/" to="/embarcador/dashboard" />}

          <Route
            path="/embarcador/dashboard"
            component={DashboardEmbarcadora}
          />
          <Route
            path="/embarcador/agendamento"
            component={EmbarcadorAgendamentoPage}
          />
          <Route
            path="/embarcador/agendamentos"
            component={EmbarcadorAgendamentosPage}
          />
          <Route path="/embarcador/frete" component={EmbarcadorFretePage} />
          <Route path="/embarcador/leilao" component={EmbarcadorLeilaoPage} />
          <Route
            path="/embarcador/favorito"
            component={EmbarcadoraFavoritoPage}
          />
          <Route
            path="/embarcador/colaborador"
            component={EmbarcadoraColaborador}
          />
          <Route
            path="/embarcador/avaliacao"
            component={EmbarcadoraAvaliacao}
          />
          <Route path="/embarcador/oferta" component={EmbarcadoraOfertar} />
          <Route
            path="/embarcador/orcamento"
            component={EmbarcadoraOrcamento}
          />
          <Route
            path="/embarcador/relatorio"
            component={EmbarcadoraRelatorio}
          />
          <Route path="/embarcador/carga" component={EmbarcadoraCarga} />
          <Route
            path="/embarcador/minha-empresa"
            component={EmbarcadoraPerfil}
          />
          <Route path="/embarcador/patio" component={EmbarcadoraPatio} />
          <Route
            path="/embarcador/classificacao"
            component={EmbarcadoraClassificacao}
          />
          <Route path="/embarcador/log" component={EmbarcadoraFreteLog} />
        </Switch>
      ) : (
        <Switch>
          {<Redirect exact from="/" to="/transportador/usina/agendamento" />}

          <Route path="/transportador/usina" component={AgendamentoUsina} />
        </Switch>
      )}

      {/* <Redirect to="/error/error-v6" /> */}
    </Suspense>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(HomePage);
