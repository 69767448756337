import axios from "axios";
import { Constants } from '../../utils/Constants';

export function cadastrarFilial(id, form = {}) {
	return axios.post(`${Constants.baseUrlEmpresa}/matriz-filial/register/${id}`, form);
}

export function deletarFilial(form = {}) {
	return axios.delete(`${Constants.baseUrlEmpresa}/matriz-filial/removeFilial`, form);
}

export function listAllFiliais() {
	return axios.get(`${Constants.baseUrlEmpresa}/matriz-filial/listAllFiliais`);
}

export function listFiliais(id) {
	return axios.get(`${Constants.baseUrlEmpresa}/matriz-filial/listFiliais/${id}`);
}

export function listFiliaisByUsuario() {
	return axios.get(`${Constants.baseUrlEmpresa}/matriz-filial/listFiliaisByUsuario`);
}

export function trocaEmpresa(form) {
	return axios.post(`${Constants.baseUrlEmpresa}/matriz-filial/trocaEmpresa`, form);
}

export function addFilial(form){
	return axios.post(`${Constants.baseUrlEmpresa}/matriz-filial/addFilial`, form);
}

export function addUsuario(form){
	return axios.post(`${Constants.baseUrlEmpresa}/matriz-filial/addUsuario`, form);
}