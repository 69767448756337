import { Constants } from '../../app/utils/Constants';

export default {
	header: {
		self: {},
		items: [
			{
				title: "Suporte via WhatsApp",
				root: true,
				alignment: "left",
				link: Constants.whatsappLink,
				icon: "fab fa-whatsapp",
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Agendamentos",
				root: true,
				icon: "fas fa-calendar",
				page: "transportador/usina/agendamento",
				bullet: "dot"
			},
			{
				title: "Cadastrar Motorista",
				root: true,
				icon: "fas fa-plus",
				page: "transportador/usina/motorista",
				bullet: "dot"
			},
		]
	}
};
