/* eslint-disable no-useless-escape */
import axios from 'axios';
import moment from 'moment';
import Swal from "sweetalert2";

export const createFormikValidation = (formMap, values) => {
	const errors = {};

	for (let [key, value] of formMap) {
		if (!values[key]) {
			errors[key] = "O campo " + value + " é obrigatório.";
		}
	}

	return errors;
};

export const formatPlacaInput = input => {
	let value = input.target.value;

	value = value.replace("-", "");
	value = value.replace(/(\D{3})(\w{4})$/, "$1-$2").toUpperCase();

	input.target.value = value;
	return input;
		
};

export const formatCNPJInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatCPFInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatHourInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1:$2");

	input.target.value = value;

	return input;
};



export const formatCNPJ = value => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	return value;
};



export const formatCepInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{5})(\d)/, "$1-$2");

	input.target.value = value;

	return input;
};

export const formatDataInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");
	value = value.replace(/(\d{2})(\d)/, "$1/$2");

	input.target.value = value;

	return input;
};

export const formatTelefoneInput = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

	input.target.value = value;

	return input;
};



export const formatIntegerInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, ''));

	if (parseInt(input.target.value) === 0) {
		input.target.value = '';
	}

	return input;
};

export const formatPesoRealCarregadoInput = input => {
	
	let cam_capacidade_ton = parseFloat(input.target.getAttribute('cam_capacidade_ton')) + 2;

	input.target.value = input.target.value.toString().replace(/[^0-9\.]/g, '');
	
	if (parseInt(input.target.value) === 0) {
		input.target.value = '';
	}
	
	if((input.target.value) > (parseFloat(cam_capacidade_ton))){
		input.target.value = '';
		Swal.fire('Ops!', 'Não é permitido informar um peso superior ao máximo permitido para este caminhão', 'error');
	}
	
	return input;
};

export const formatPesoRealDescarregadoInput = input => {

	let cam_capacidade_ton = parseFloat(input.target.getAttribute('cam_capacidade_ton')) + 2;

	input.target.value = input.target.value.toString().replace(/[^0-9\.]/g, '');

	if (parseInt(input.target.value) === 0) {
		input.target.value = '';
	}

	if((input.target.value) > (parseFloat(cam_capacidade_ton))){
		input.target.value = '';
		Swal.fire('Ops!', 'Não é permitido informar um peso superior ao máximo permitido para este caminhão', 'error');
	}

	return input;
};

export const formatBRLInput = input => {
	input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

	input.target.value = formatBRL(input.target.value);

	return input;
};

export const extrairValor = value => {
	let regex = /^[0-9.,]+$/;
	var match = value.match(regex);
	return match ? match[0] : null;
};

export const exp = /^\w{0,3}\W?\s?(\d+)[.,](\d+)?,?(\d+)?$/g;
export const replacer = (f, group1, group2, group3) => {
	return group3 ?
		`${group1}${group2}.${group3}` :
		`${group1}.${group2}`;
};


export const formatBRL = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2
	});

	value = formatter.format(value);

	return value;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
	if (input.target.value.length > 8) {
		var cep = input.target.value.replace(/[^0-9]/, "");

		const instance = axios.create();

		instance.get(`https://viacep.com.br/ws/${cep}/json/`, {}).then(res => {
			setFieldValue(nomeEndereco, res.data.logradouro);
			setFieldValue(nomeBairro, res.data.bairro);
			setFieldValue(nomeCidade, res.data.localidade);
			setFieldValue(nomeUf, res.data.uf);
		}).catch(err => {
		});
	}

	return formatCepInput(input);
};

export const limparMoeda = (valor) => {
	valor = valor.replace("R$", "");
	valor = valor.replace(".", "");
	valor = valor.replace(",", ".");
	valor = valor.replace('/^\p{Z}+|\p{Z}+$/u', '');
	valor = valor.trim();

	return valor;
};

export const limparTelefone = (valor) => {
	valor = valor.replace(' ', '');
	valor = valor.replace('-', '');
	valor = valor.replace('(', '');
	valor = valor.replace(')', '');
	valor = valor.replace('/[^A-Za-z0-9\-]/', '');
	valor = valor.replace('/-+/', '-');
	valor = valor.trim();

	return valor;
};

export const apenasNumerosPositivos = (input) => {
	let valor = input.target.value;

	if (valor.length <= 1) valor = valor.replace(/[^1-9]/g, '');

	valor = valor.replace(' ', '');
	valor = valor.replace(/[^0-9]/g, '');
	valor = valor.trim();

	input.target.value = valor;

	return input;
};

export const listaUfs = [
	'AC',
	'AL',
	'AM',
	'AP',
	'BA',
	'CE',
	'DF',
	'ES',
	'GO',
	'MA',
	'MG',
	'MS',
	'MT',
	'PA',
	'PB',
	'PE',
	'PI',
	'PR',
	'RJ',
	'RN',
	'RO',
	'RR',
	'RS',
	'SC',
	'SE',
	'SP',
	'TO'
];

export const displayValueCurrency = (value) => {
	return value ? `R$ ${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : "Não disponível";
};

export const displayValueFixed = (value, casas) => {
	return value ? value.toFixed(casas) : "Não disponível";
};

export const displayValuePercent = (value) => {
	return value ? `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })} %` : "Não disponível";
};

export const getTipoProduto = (tipos) => {
	if (tipos) {
		let tipos_produto = '';

		tipos.forEach((produto, index) => {
			tipos_produto += tipos.length - 1 !== index ? (produto.ftp_tipo_produto + ', ') : produto.ftp_tipo_produto;
		});

		return tipos_produto;
	}
};

export const getTipoCaminhao = (tipos) => {
	if (tipos) {
		let tipos_caminhao = '';

		tipos.forEach((caminhao, index) => {
			tipos_caminhao += tipos.length - 1 !== index ? (caminhao.ftc_tipo_caminhao + ', ') : caminhao.ftc_tipo_caminhao;
		});

		return tipos_caminhao;
	}
};

export const getTipoCaminhoesAgendamentoFrete = (tipos) => {
	if (tipos) {
		let tipos_caminhao = '';

		tipos.forEach((caminhao, index) => {
			tipos_caminhao += tipos.length - 1 !== index ? (caminhao.fat_tipo_caminhao + ', ') : caminhao.fat_tipo_caminhao;
		});

		return tipos_caminhao;
	}
};

export const getTipoCarroceriasAgendamentoFrete = (tipos) => {
	if (tipos) {
		let tipos_carrocerias = '';
		if(tipos.length == 0){
			return 'N/A';
		}
		tipos.forEach((carroceria, index) => {
			tipos_carrocerias += tipos.length - 1 !== index ? (carroceria.cta_tipo_carroceria + ', ') : carroceria.cta_tipo_carroceria;
		});

		return tipos_carrocerias;
	}
};

export const getTipos = (tipos) => {
	return tipos ? tipos.map((value) => value['ftp_tipo_produto']).join(', ') : '';
};

export const getHorarioEntrada = (values) => {
	const { previsao_entrada_data, previsao_entrada_hora } = values;

	if (!previsao_entrada_data || !previsao_entrada_hora) {
		return 'N/A';
	}

	return moment(`${previsao_entrada_data} ${previsao_entrada_hora}`).format('DD/MM/YYYY [às] HH:mm A');
};

export const getHorarioSaida = (values) => {
	const { previsao_saida_data, previsao_saida_hora } = values;

	if (!previsao_saida_data || !previsao_saida_hora) {
		return 'N/A';
	}

	return moment(`${previsao_saida_data} ${previsao_saida_hora}`).format('DD/MM/YYYY [às] HH:mm A');
};

export const validateNumeroEixos = (precos_estacionamento, motorista_obj) => {
	let isValid = false;

	if (!motorista_obj) {
		return false;
	}

	precos_estacionamento.forEach(preco => {
		if (preco.sep_numero_eixos == motorista_obj.cam_numero_eixos) {
			isValid = true;
		}
	});

	return isValid;
};

export const getEstacionamentoPreco = (precos_estacionamento, total_horas_servico, motorista_obj) => {
	let preco = null;

	const precosComEixosValidados = precos_estacionamento.filter(obj => obj.sep_numero_eixos == motorista_obj.cam_numero_eixos);

	if (precosComEixosValidados.length < 1) return null;

	precosComEixosValidados.forEach((obj, index) => {
		if (obj.sep_horario_inicio <= total_horas_servico && obj.sep_horario_fim >= total_horas_servico && !preco) {
			preco = obj.sep_preco;
		}
	});

	if (!preco) {
		const precoObj = precosComEixosValidados.reduce((prev, current) => prev.sep_horario_fim > current.sep_horario_fim ? prev : current);

		preco = precoObj.sep_preco;
	}

	return preco;
};

export const getHorasTotaisEstacionamento = (values) => {
	const {
		previsao_entrada_data,
		previsao_entrada_hora,
		previsao_saida_data,
		previsao_saida_hora
	} = values;

	if (!previsao_entrada_data || !previsao_entrada_hora || !previsao_saida_data || !previsao_saida_hora) return null;

	const entrada = moment(`${previsao_entrada_data} ${previsao_entrada_hora}`);
	const saida = moment(`${previsao_saida_data} ${previsao_saida_hora}`);

	let horas = Math.ceil(
		saida.diff(entrada, 'hours', true).toFixed(2)
	);

	if (horas < 0) {
		return null;
	}

	return horas;
};

export const getClassCaracteristicaProduto = caracteristica => {
	return caracteristica === "Produto não perigoso" ? "text-frete-tipo-produto mb-10 color-nao-perigoso" : "text-frete-tipo-produto mb-10 color-perigoso";
};

export const shallowEqual = (object1, object2) => {
	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (object1[key] !== object2[key]) {
			return false;
		}
	}

	return true;
};



export const formatPlacaInputFilter = input => {
	let value = input.target.value;

	value = value.replace("-", "");
	value = value.replace(/(\D{3})(\w{4})$/, "$1-$2").toUpperCase();

	return value;
		
};

export const formatCNPJInputFilter = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1/$2");
	value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

	return value;
};

export const formatCPFInputFilter = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d)/, "$1.$2");
	value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");


	return value;
};


export const formatTelefoneInputFilter = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{0})(\d)/, "$1($2");
	value = value.replace(/(\d{2})(\d)/, "$1) $2");
	value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");


	return value;
};


export const formatHourInputFilter = input => {
	let value = input.target.value;

	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "$1:$2");



	return value;
};